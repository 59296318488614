import React from "react";
import {
  Letters,
  LinkHome,
  NotFoundContainer,
  Subtitle,
  Title,
} from "../components/library";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404" />
      <NotFoundContainer>
        <Title>
          4<Letters>0</Letters>4<Letters>...</Letters>
        </Title>
        <Subtitle>
          I think you might be lost... Let me take you back to
          <LinkHome to="/"> safety</LinkHome>
        </Subtitle>
      </NotFoundContainer>
    </Layout>
  );
};

export default NotFoundPage;
